
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

body {
  padding-top: 56px;
}

.mw-50 { max-width: 50% !important; }


.noUi-tooltip {
    display: none !important;
}
.noUi-active .noUi-tooltip {
    display: block !important;
}

.noUi-primary {
  .noUi-connect {
    background: $primary;
  }
}

.noUi-success {
  .noUi-connect {
    background: $success;
  }
}

.noUi-info {
  .noUi-connect {
    background: $info;
  }
}

.noUi-danger {
  .noUi-connect {
    background: $danger;
  }
}


.sim-text-small {
  font-size: 0.8em;
}

.sim-text-normal {
  font-size: 1em;
}

.sim-text-medium {
  font-size: 1.3em;
}

.sim-text-big {
  font-size: 1.5em;
}




.sim-card {
  .card-title {
    font-size: 1.3em;
  }
}